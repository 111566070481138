import { Container, createStyles, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

interface Props {
    children: NonNullable<ReactNode>,
}

const useStyles = makeStyles(() => createStyles({
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flex: 1,
    },
}));

export default function MainContent(props: Props) {
    const { children } = props;
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <Toolbar />
            <Container className={classes.container}>
                {children}
            </Container>
        </main>
    );
}
