import { createReducer } from 'deox';
import { cardAdded, cardChanged, cardRemoved } from '../actions/firebaseCardActions';
import { setAdded, setChanged, setRemoved } from '../actions/firebaseSetActions';
import { setSelected } from '../actions/setUiActions';
import {
    addCard,
    addSide,
    changeText,
    showNextCard,
    showPreviousCard,
    turnCardLeft,
    turnCardRight,
} from '../actions/vocabularyCardUiActions';
import Card from '../types/firestore/Card';
import { LoadingState } from '../types/state/CallState';
import VocabularyCard from '../types/state/VocabularyCard';
import VocabularyCardDirection from '../types/state/VocabularyCardDirection';
import VocabularyCardSide from '../types/state/VocabularyCardSide';
import VocabularyState from '../types/state/VocabularyState';
import { addItem, getNextEntryId, getPreviousEntryId, removeItem } from './firebaseCollectionReducerHelper';

const initialState: VocabularyState = {
    callState: LoadingState.LOADED,
    sets: {},
    setStack: [],
    cards: {},
    cardStack: [],
    currentSet: undefined,
    currentCard: undefined,
    currentSide: undefined,
    changeDirection: undefined,
    gotoNextNewCard: false,
};

function getCurrentSide(state: VocabularyState) {
    if (state.currentSide === undefined) {
        return;
    }

    const card = state.cards[state.currentCard!];

    return card?.sides?.find(side => side.id === state.currentSide);
}

function getFirstSide(card: VocabularyCard): VocabularyCardSide | undefined {
    return card.sides[0];
}

function getLastSide(card: VocabularyCard): VocabularyCardSide | undefined {
    return card.sides[card.sides.length - 1];
}

function gotoCard(state: VocabularyState, targetCardId?: string): VocabularyState {
    const isCurrentCard = targetCardId === state.currentCard;

    if (isCurrentCard || !targetCardId || !state.cards[targetCardId]) {
        return state;
    }

    const currentIndex = state.cardStack.indexOf(state.currentCard ?? '');
    const targetIndex = state.cardStack.indexOf(targetCardId);
    const targetCard = state.cards[targetCardId];

    if (!state.currentCard || currentIndex < 0) {
        return {
            ...state,
            currentCard: targetCardId,
            currentSide: getFirstSide(targetCard)?.id,
            changeDirection: undefined,
        };
    }

    const currentCard = state.cards[state.currentCard];
    const currentSide = getCurrentSide(state);
    const currentSideIndex = currentCard?.sides?.findIndex(side => side.id === currentSide?.id) ?? -1;
    const targetSide = targetCard.sides[currentSideIndex] ?? getLastSide(targetCard);

    return {
        ...state,
        currentCard: targetCardId,
        currentSide: targetSide.id,
        changeDirection: targetIndex > currentIndex ? VocabularyCardDirection.down : VocabularyCardDirection.up,
    };
}

function gotoSide(state: VocabularyState, target: string | 'previous' | 'next', startIndex?: number) {
    const currentCard = state.cards[state.currentCard!];
    const currentSide = getCurrentSide(state);

    if (!currentCard) {
        return state;
    }

    const currentIndex = startIndex ?? currentCard.sides.findIndex(side => side === currentSide);
    let targetIndex: number;
    let changeDirection: VocabularyCardDirection | undefined = undefined;

    if (target === 'next') {
        targetIndex = currentIndex + 1;
        changeDirection = VocabularyCardDirection.right;
    } else if (target === 'previous') {
        targetIndex = currentIndex - 1;
        changeDirection = VocabularyCardDirection.left;
    } else {
        targetIndex = currentCard?.sides?.findIndex(side => side.id === target) ?? -1;
        changeDirection = targetIndex > currentIndex ? VocabularyCardDirection.right : VocabularyCardDirection.left;
    }

    if (targetIndex >= currentCard.sides.length) {
        targetIndex = 0;
    } else if (targetIndex < 0) {
        targetIndex = currentCard.sides.length - 1;
    }

    const targetSide = currentCard.sides[targetIndex];

    if (!targetSide) {
        return state;
    }

    return {
        ...state,
        currentSide: targetSide.id,
        changeDirection,
    };
}

function transformFirebaseCard(id: string, card: Card): VocabularyCard {
    const sides = card.sides ?? [];
    const transformedSides = sides
        .map<VocabularyCardSide>(side => ({
            id: side.id,
            text: side.text,
            createdAtMs: side.createdAt?.toMillis() ?? -1,
        }));

    return {
        ...card,
        id,
        sides: transformedSides,
    };
}

export default createReducer(initialState, handleAction => [
    handleAction(setAdded, (state, { payload }) => {
        const set = {
            id: payload.id,
            ...payload.set,
        };

        const { newMap, newStack, currentItem } = addItem(state.sets, state.setStack, set, payload.newIndex, state.currentSet);

        return {
            ...state,
            sets: newMap,
            setStack: newStack,
            currentSet: currentItem,
            callState: LoadingState.LOADED,
        };
    }),
    handleAction(setRemoved, (state, { payload }) => {
        const set = {
            id: payload.id,
            ...payload.set,
        };

        const { newMap, newStack, currentItem } = removeItem(state.sets, state.setStack, set, state.currentSet);
        return {
            ...state,
            currentSet: currentItem,
            sets: newMap,
            setStack: newStack,
            cards: state.currentSet === set.id ? {} : state.cards,
            cardStack: state.currentSet === set.id ? [] : state.cardStack,
        }
    }),
    handleAction(setChanged, (state, { payload }) => {
        const set = {
            id: payload.id,
            ...payload.set,
        };

        const { newMap, newStack } = addItem(state.sets, state.setStack, set, payload.newIndex);

        return {
            ...state,
            sets: newMap,
            setStack: newStack,
        };
    }),
    handleAction(setSelected, (state, { payload }) => {
        return {
            ...state,
            currentSet: payload.id,
            currentCard: undefined,
            currentSide: undefined,
            cards: {},
            cardStack: [],
        };
    }),
    handleAction(addCard, (state) => ({
        ...state,
        gotoNextNewCard: true,
    })),
    handleAction(cardAdded, (state, { payload }) => {
        const newCard = transformFirebaseCard(payload.id, payload.card);

        const { newStack, newMap } = addItem(state.cards, state.cardStack, newCard, payload.newIndex, state.currentCard);

        const newState = {
            ...state,
            cards: newMap,
            cardStack: newStack,
            callState: LoadingState.LOADED,
            gotoNextNewCard: false,
        };

        if (state.gotoNextNewCard || !state.currentCard) {
            return gotoCard(newState, payload.id);
        }

        return newState;
    }),
    handleAction(cardRemoved, (state, { payload }) => {
        const card = transformFirebaseCard(payload.id, payload.card);
        const { newStack, newMap, currentItem } = removeItem(state.cards, state.cardStack, card, state.currentCard);

        let newState: VocabularyState = {
            ...state,
            changeDirection: undefined,
        };

        if (card.id === state.currentCard) {
            newState = gotoCard(newState, currentItem);
        }

        return {
            ...newState,
            cards: newMap,
            cardStack: newStack,
        };
    }),
    handleAction(cardChanged, (state, { payload }) => {
        const changedCard = transformFirebaseCard(payload.id, payload.card);
        const currentCard = state.cards[state.currentCard!];
        const { newStack, newMap, currentItem } = addItem(state.cards, state.cardStack, changedCard, payload.newIndex, state.currentCard);

        const newState = {
            ...state,
            cards: newMap,
            cardStack: newStack,
            gotoNextNewCard: false,
            changeDirection: undefined,
            currentCard: currentItem,
        };

        if (!currentCard || currentCard.id !== changedCard.id) {
            return newState;
        }

        const originalCurrentSide = getCurrentSide(state);
        const newCurrentSide = getCurrentSide(newState);
        const originalCurrentSideIndex = currentCard.sides.findIndex(side => side === originalCurrentSide);

        if (!newCurrentSide) {
            // The current side was removed, go to previous side
            return gotoSide(newState, 'previous', originalCurrentSideIndex);
        }

        const newSide = changedCard.sides.find(side => !currentCard.sides.some(s => s.id === side.id));

        // Goto new side if current card receives one an flag was set
        if (state.gotoNextNewCard && newSide) {
            return gotoSide(newState, newSide.id, originalCurrentSideIndex);
        }

        return newState;
    }),
    handleAction(changeText, (state, { payload }) => {
        if (!state.currentCard || !state.cards[state.currentCard]) {
            return state;
        }

        return {
            ...state,
            cards: {
                ...state.cards,
                [state.currentCard]: {
                    ...state.cards[state.currentCard],
                    sides: state.cards[state.currentCard].sides.map((side) => {
                        if (side.id !== state.currentSide) {
                            return side;
                        }

                        return { ...side, text: payload };
                    })
                },
            }
        };
    }),
    handleAction(turnCardLeft, (state) => {
        return gotoSide(state, 'previous');
    }),
    handleAction(turnCardRight, (state) => {
        return gotoSide(state, 'next');
    }),
    handleAction(showPreviousCard, (state) => {
        const previousCard = getPreviousEntryId(state.cardStack, state.currentCard);
        return gotoCard(state, previousCard);
    }),
    handleAction(showNextCard, (state) => {
        const nextCard = getNextEntryId(state.cardStack, state.currentCard);
        return gotoCard(state, nextCard);
    }),
    handleAction(addSide, (state) => ({
        ...state,
        gotoNextNewCard: true,
    })),
]);
