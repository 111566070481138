import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import {
    addSide,
    changeText,
    deleteSide,
    showNextCard,
    showPreviousCard,
    turnCardLeft,
    turnCardRight,
} from '../actions/vocabularyCardUiActions';
import {
    getChangeDirection,
    getCurrentCardNumber,
    getCurrentSideNumber,
    getCurrentVocable, getNumberOfCards,
    getNumberOfSides,
} from '../selectors/vocabularySelectors';
import VocabularyCardDirection from '../types/state/VocabularyCardDirection';
import VocabularyCard from './VocabularyCard';

interface AnimationProps {
    currentSide: number;
    maxSides: number;
    currentCard: number;
    maxCards: number;
    changeDirection?: string;
}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        cardRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            perspective: '1000px',
        },
        cardContainer: {
            position: 'relative',
            overflow: 'hidden',
            fontSize: 'calc(2em + 6vmin)',
            height: `100vmin`,
            width: `100vmin`,
            minHeight: '300px',
            minWidth: '300px',
            [theme.breakpoints.down('xs')]: {
                height: `calc(100vmin - ${theme.spacing(8)}px)`,
                width: `calc(100vmin - ${theme.spacing(8)}px)`,
            },
            '@media (min-aspect-ratio: 3/4)': {
                height: `calc(100vmin - ${theme.spacing(16)}px)`,
                width: `calc(100vmin - ${theme.spacing(16)}px)`,
            },
        },
        animationContainer: {
            transformStyle: 'preserve-3d',
            backfaceVisibility: 'hidden',
            width: '100%',
            height: '100%',
            padding: theme.spacing(2, 2),
        },
        addCard: {
            position: 'absolute',
            right: `${theme.spacing(2)}px`,
            bottom: `${theme.spacing(2)}px`,
        },
    });
});

function getFlipFactor({ changeDirection }: AnimationProps) {
    if (changeDirection === VocabularyCardDirection.left) {
        return 1;
    } else if (changeDirection === VocabularyCardDirection.right) {
        return -1;
    }

    return 0;
}

function getScrollFactor({ changeDirection }: AnimationProps) {
    if (changeDirection === VocabularyCardDirection.up) {
        return -1;
    } else if (changeDirection === VocabularyCardDirection.down) {
        return 1;
    }

    return 0;
}

const animationSpeed = .2;

const animationVariants: Variants = {
    visible: {
        opacity: 1,
        rotateY: 0,
        translateY: '0px',
        transition: {
            duration: animationSpeed,
            delay: animationSpeed,
        },
    },
    initial(args: AnimationProps) {
        const { changeDirection } = args;
        const flipFactor = getFlipFactor(args);
        const scrollFactor = getScrollFactor(args);

        return {
            opacity: changeDirection ? Math.abs(flipFactor) : 1,
            rotateY: flipFactor * -90,
            translateY: `${scrollFactor * 100}%`,
            transition: {
                duration: animationSpeed,
            },
        };
    },
    invisible(args: AnimationProps) {
        const flipFactor = getFlipFactor(args);
        const scrollFactor = getScrollFactor(args);

        return {
            opacity: 1,
            rotateY: flipFactor * 90,
            translateY: `${scrollFactor * -100}%`,
            transition: {
                duration: animationSpeed,
            },
        };
    },
};

export function VocabularyCardSwiper() {
    const classes = useStyles();
    const vocable = useSelector(getCurrentVocable);
    const currentSide = useSelector(getCurrentSideNumber);
    const maxSides = useSelector(getNumberOfSides);
    const currentCard = useSelector(getCurrentCardNumber);
    const maxCards = useSelector(getNumberOfCards);
    const changeDirection = useSelector(getChangeDirection);
    const dispatch = useDispatch();

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => dispatch(turnCardRight()),
        onSwipedRight: () => dispatch(turnCardLeft()),
        onSwipedUp: () => dispatch(showNextCard()),
        onSwipedDown: () => dispatch(showPreviousCard()),
        preventDefaultTouchmoveEvent: true,
    });

    const animationProps: AnimationProps = {
        currentSide,
        maxSides,
        currentCard,
        maxCards,
        changeDirection,
    };

    return (
        <div className={classes.cardRow}>
            <div
                className={classes.cardContainer}
                {...swipeHandlers}
            >
                <AnimatePresence custom={animationProps}>
                    <motion.div
                        className={classes.animationContainer}
                        variants={animationVariants}
                        animate="visible"
                        initial="initial"
                        exit="invisible"
                        key={`${currentCard}-${currentSide}-${maxSides}-${maxCards}`}
                        custom={animationProps}
                    >
                        <VocabularyCard
                            vocable={vocable}
                            currentSide={currentSide}
                            maxSides={maxSides}
                            onChange={text => dispatch(changeText(text))}
                            onClickPreviousSide={() => dispatch(turnCardLeft())}
                            onClickNextSide={() => dispatch(turnCardRight())}
                            onClickDelete={() => dispatch(deleteSide())}
                            onClickAdd={() => dispatch(addSide())}
                        />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
}
