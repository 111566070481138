import { ofType } from 'deox';
import firebase from 'firebase/app';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { EMPTY, merge, Observable } from 'rxjs';
import { debounceTime, switchMap, switchMapTo } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { addSide, changeText, deleteSide } from '../actions/vocabularyCardUiActions';
import { getCurrentCard, getCurrentSet, getCurrentSideIndex } from '../selectors/vocabularySelectors';
import { Side } from '../types/firestore/Side';
import RootState from '../types/state/RootState';
import StoreDependencies from '../types/state/StoreDependencies';
import VocabularyCard from '../types/state/VocabularyCard';

function transformSides(card: VocabularyCard): Side[] {
    return card.sides.map(side => ({
        id: side.id,
        createdAt: firebase.firestore.Timestamp.fromMillis(side.createdAtMs),
        text: side.text,
    }));
}

function createSide(text: string): Side {
    return {
        id: uuidv4(),
        createdAt: firebase.firestore.Timestamp.now(),
        text,
    };
}

async function updateSides(firestore: firebase.firestore.Firestore, state: RootState, updateFn: (index: number, sides: Side[]) => Side[]) {
    const set = getCurrentSet(state);
    const card = getCurrentCard(state);

    if (!set || !card) {
        return;
    }

    const setRef = firestore.collection('sets').doc(set.id);
    const cardRef = setRef.collection('cards').doc(card.id);

    const currentSideIndex = getCurrentSideIndex(state);
    const sides = updateFn(currentSideIndex, transformSides(card));

    if (sides.length === 0) {
        await cardRef.delete();
        await setRef.update({ cardCount: firebase.firestore.FieldValue.increment(-1) })
        return;
    }

    await cardRef.update({ sides });
}

async function updateCurrentText(firestore: firebase.firestore.Firestore, state: RootState, text: string): Promise<void> {
    await updateSides(firestore, state, (index, sides) => {
        if (index < 0) {
            sides.push(createSide(text));

            return sides;
        }

        sides[index].text = text;

        return sides;
    });
}

async function addNewSide(firestore: firebase.firestore.Firestore, state: RootState, text: string): Promise<void> {
    await updateSides(firestore, state, (index, sides) => {
        sides.splice(index + 1, 0, createSide(text));

        return sides;
    });
}

async function removeCurrentSide(firestore: firebase.firestore.Firestore, state: RootState): Promise<void> {
    await updateSides(firestore, state, (index, sides) => {
        sides.splice(index, 1);

        return sides;
    });
}

export default function saveUpdatedCardEpic(
    actions$: Observable<Action>,
    state: StateObservable<RootState>,
    { firestore }: StoreDependencies,
): Observable<Action> {
    const changeText$ = actions$.pipe(
        ofType(changeText),
        debounceTime(300),
        switchMap(({ payload }) => updateCurrentText(firestore, state.value, payload)),
    );

    const addSide$ = actions$.pipe(
        ofType(addSide),
        switchMap(() => addNewSide(firestore, state.value, '')),
    );

    const deleteSide$ = actions$.pipe(
        ofType(deleteSide),
        switchMap(() => removeCurrentSide(firestore, state.value)),
    );

    return merge(
        changeText$,
        addSide$,
        deleteSide$,
    ).pipe(
        switchMapTo(EMPTY),
    );
}
