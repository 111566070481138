import { ofType } from 'deox';
import firebase from 'firebase/app';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, switchMapTo } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { addCard } from '../actions/vocabularyCardUiActions';
import { getCurrentSet } from '../selectors/vocabularySelectors';
import Card from '../types/firestore/Card';
import RootState from '../types/state/RootState';
import StoreDependencies from '../types/state/StoreDependencies';

async function pushCard(firestore: firebase.firestore.Firestore, state: RootState): Promise<void> {
    const set = getCurrentSet(state);

    if (!set) {
        return;
    }

    const setRef = firestore.collection('sets').doc(set.id);
    const cardsRef = setRef.collection('cards');

    const newCardData: Card = {
        // createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        createdAt: firebase.firestore.Timestamp.now(),
        sides: [{
            id: uuidv4(),
            createdAt: firebase.firestore.Timestamp.now(),
            text: '',
        }],
    };

    await cardsRef.add(newCardData);
    await setRef.update({ cardCount: firebase.firestore.FieldValue.increment(1) })
}

export default function saveNewCardEpic(
    actions$: Observable<Action>,
    state: StateObservable<RootState>,
    { firestore }: StoreDependencies,
): Observable<Action> {
    return actions$.pipe(
        ofType(addCard),
        switchMap(() => pushCard(firestore, state.value)),
        switchMapTo(EMPTY),
    );
}
