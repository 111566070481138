import { createAction } from 'deox';

export const turnCardRight = createAction('[Vocabulary Card UI] Turn right');

export const turnCardLeft = createAction('[Vocabulary Card UI] Turn left');

export const addSide = createAction('[Vocabulary Card UI] Add side right');

export const deleteSide = createAction('[Vocabulary Card UI] Delete side');

export const showPreviousCard = createAction('[Vocabulary Card UI] Previous card');

export const showNextCard = createAction('[Vocabulary Card UI] Next card');

export const addCard = createAction('[Vocabulary Card UI] Add card');

export const changeText = createAction(
    '[Vocabulary Card] Change text',
    resolve => (text: string) => resolve(text),
);
