import { ofType } from 'deox';
import firebase from 'firebase/app';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, switchMapTo } from 'rxjs/operators';
import { setDeletionRequested } from '../actions/setUiActions';
import CardSet from '../types/state/CardSet';
import RootState from '../types/state/RootState';
import StoreDependencies from '../types/state/StoreDependencies';

async function deleteSet(firestore: firebase.firestore.Firestore, set: CardSet): Promise<void> {
    await firestore
        .collection('sets')
        .doc(set.id)
        .delete();
}

export default function deleteSetEpic(
    actions$: Observable<Action>,
    state: StateObservable<RootState>,
    { firestore }: StoreDependencies,
): Observable<Action> {
    return actions$.pipe(
        ofType(setDeletionRequested),
        switchMap(({ payload }) => deleteSet(firestore, payload)),
        switchMapTo(EMPTY),
    );
}
