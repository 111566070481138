import {
    Button,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import OptionsIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useMemo, useState } from 'react';
import { Fragment } from 'react';
import CardSet from '../types/state/CardSet';

interface Props {
    sets: CardSet[];
    selectedSet?: CardSet;
    onSelect?(set: CardSet): void;
    onEdit?(set: CardSet): void;
    onCreate?(): void;
}

interface MenuState {
    anchor: HTMLElement;
    set: CardSet;
}

interface GroupedSet extends CardSet {
    shortTitle: string;
}

interface SetGroup {
    name: string | null;
    sets: GroupedSet[];
}

function useGroupedSets(sets: CardSet[]) {
    return useMemo(() => {
        const groups = new Map<string | null, SetGroup>();

        sets.forEach((set) => {
            const lastDelimiter = set.title.lastIndexOf('/');
            const groupTitle = set.title.substr(0, lastDelimiter).trim() || null;
            const shortTitle = set.title.substr(lastDelimiter + 1).trim();

            if (!groups.has(groupTitle)) {
                groups.set(groupTitle, { name: groupTitle, sets: [] });
            }

            groups.get(groupTitle)?.sets.push({
                ...set,
                shortTitle,
            });
        });

        return Array
            .from(groups.values())
            .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
    }, [sets]);
}

export default function SetList(props: Props) {
    const { sets, selectedSet, onCreate, onSelect, onEdit } = props;

    const [menu, setMenu] = useState<null | MenuState>(null);
    const groups = useGroupedSets(sets);
    const handleEdit = useCallback(() => {
        if (!menu) {
            return;
        }

        setMenu(null);
        onEdit?.(menu.set);
    }, [menu, onEdit]);

    return (
        <List>
            {groups.map(group => (
                <Fragment key={group.name}>
                    {group.name && <ListSubheader>{group.name}</ListSubheader>}
                    {group.sets.map(set => (
                        <ListItem
                            key={set.id}
                            button
                            selected={set.id === selectedSet?.id}
                            onClick={() => onSelect?.(set)}
                        >
                            <ListItemText>{set.shortTitle}</ListItemText>
                            <ListItemSecondaryAction>
                                <Chip label={set.cardCount} size="small" />
                                <IconButton
                                    edge="end"
                                    onClick={event => setMenu({ set, anchor: event.currentTarget})}
                                >
                                    <OptionsIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </Fragment>
            ))}
            {onCreate && (
                <ListItem>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        fullWidth
                        onClick={() => onCreate()}
                    >
                        Neues Set
                    </Button>
                </ListItem>
            )}
            <Menu
                anchorEl={menu?.anchor}
                keepMounted
                open={Boolean(menu)}
                onClose={() => setMenu(null)}
            >
                {onEdit && <MenuItem onClick={handleEdit}>Bearbeiten</MenuItem>}
            </Menu>
        </List>
    );
}
