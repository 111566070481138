import { AppBar as MuiAppBar, createStyles, IconButton, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useContext } from 'react';
import DrawerContext from './DrawerContext';

interface Props {
    showDrawerToggle?: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    container: {
        zIndex: theme.zIndex.drawer + 1,
    },
    navigationToggleButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function AppBar(props: Props) {
    const classes = useStyles(props);
    const {
        showDrawerToggle = true,
    } = props;

    const { toggle } = useContext(DrawerContext);

    return (
        <MuiAppBar position="fixed" className={classes.container}>
            <Toolbar>
                {showDrawerToggle && (
                    <IconButton
                        color="inherit"
                        aria-label="toggle drawer"
                        edge="start"
                        className={classes.navigationToggleButton}
                        onClick={() => toggle()}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Typography variant="h6">
                    Vocabulary
                </Typography>
            </Toolbar>
        </MuiAppBar>
    );
}
