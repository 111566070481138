import { createStyles, Drawer, Hidden, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, useContext } from 'react';
import DrawerContext from './DrawerContext';

interface Props {
    shrinkToIcon?: boolean,
    children?: ReactNode,
}

interface StyleProps extends Props {
    isOpen: boolean;
}

const drawerWidth = 300;

const useStyles = makeStyles(theme => createStyles({
    drawer({ isOpen, shrinkToIcon }: StyleProps) {
        if (!isOpen && !shrinkToIcon) {
            return {};
        }

        const styles = {
            width: drawerWidth,
            flexShrink: 0,
        };

        if (!shrinkToIcon) {
            return styles;
        }

        if (isOpen) {
            return {
                ...styles,
                whiteSpace: 'nowrap',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            };
        }

        return {
            ...styles,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        };
    },
    drawerContainer: {
        overflow: 'auto',
    },
}));

export default function NavigationDrawer(props: Props) {
    const { isOpen, toggle } = useContext(DrawerContext);

    const styleProps: StyleProps = { ...props, isOpen };
    const classes = useStyles(styleProps);

    const { shrinkToIcon, children } = props;

    return (
        <>
            <nav>
                <Hidden smUp>
                    <Drawer
                        variant="temporary"
                        open={isOpen}
                        onClose={() => toggle()}
                        classes={{
                            root: classes.drawer,
                            paper: classes.drawer,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {children}
                    </Drawer>
                </Hidden>
                <Hidden xsDown>
                    <Drawer
                        variant="persistent"
                        open={isOpen || shrinkToIcon}
                        classes={{
                            root: classes.drawer,
                            paper: classes.drawer,
                        }}
                    >
                        <Toolbar />
                        <div className={classes.drawerContainer}>
                            {children}
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
        </>
    );
}
