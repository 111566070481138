export enum LoadingState {
    INIT = 'init',
    LOADING = 'loading',
    LOADED = 'loaded',
}

export type CallError = {
    error: string;
}

export type CallState = LoadingState | CallError;
