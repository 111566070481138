interface CollectionItem {
    id: string;
}

export function cleanUp<T extends CollectionItem>(
    map: Record<CollectionItem['id'], T>,
    stack: CollectionItem['id'][],
) {
    const newMap = { ...map };

    Object
        .keys(map)
        .filter(id => !stack.includes(id))
        .forEach(id => delete newMap[id]);

    const remainingMapIds = Object.keys(newMap);
    const newStack = stack.filter(id => remainingMapIds.includes(id));

    return { newMap, newStack };
}

export function addItem<T extends CollectionItem>(
    map: Record<CollectionItem['id'], T>,
    stack: CollectionItem['id'][],
    item: T,
    newIndex?: number,
    currentItem?: CollectionItem['id'],
) {
    const newMap = { ...map };
    const newStack = [...stack];

    newMap[item.id] = item;

    const oldIndex = newStack.indexOf(item.id);

    if (oldIndex >= 0) {
        newStack.splice(oldIndex, 1);
    }

    if (newIndex !== undefined) {
        newStack.splice(newIndex, 0, item.id);
    } else {
        newStack.push(item.id);
    }

    return {
        ...cleanUp(newMap, newStack),
        oldIndex,
        newIndex: newStack.indexOf(item.id),
        currentItem: currentItem ?? item.id,
    };
}

export function removeItem<T extends CollectionItem>(
    map: Record<CollectionItem['id'], T>,
    stack: CollectionItem['id'][],
    item: T,
    currentItem?: CollectionItem['id'],
) {
    const newMap = { ...map };
    const newStack = [...stack];

    delete newMap[item.id];

    const oldIndex = newStack.indexOf(item.id);
    let newCurrentItem = currentItem;

    if (oldIndex >= 0) {
        if (item.id === currentItem) {
            newCurrentItem = getNextEntryId(newStack, item.id) ?? getPreviousEntryId(newStack, item.id);
        }

        newStack.splice(oldIndex, 1);
    }

    return {
        ...cleanUp(newMap, newStack),
        oldIndex,
        currentItem: newCurrentItem,
    };
}

export function getNextEntryId(stack: CollectionItem['id'][], current?: CollectionItem['id']) {
    if (!current) {
        return;
    }

    const index = stack.indexOf(current);

    if (index < 0) {
        return;
    }

    return stack[index + 1];
}

export function getPreviousEntryId(stack: CollectionItem['id'][], current?: CollectionItem['id']) {
    if (!current) {
        return;
    }

    const index = stack.indexOf(current);

    if (index < 0) {
        return;
    }

    return stack[index - 1];
}
