import { createContext } from 'react';

export interface DrawerContextValue {
    isOpen: boolean;
    toggle(): void;
}

const DrawerContext = createContext<DrawerContextValue>({
    isOpen: true,
    toggle: () => null,
});
export default DrawerContext;
