import { CallError, CallState, LoadingState } from '../types/state/CallState';

export function isInitial(state: CallState) {
    return state === LoadingState.INIT;
}

export function isLoading(state: CallState) {
    return state === LoadingState.LOADING;
}

export function isLoaded(state: CallState) {
    return state === LoadingState.LOADED;
}

export function isError(state: CallState): state is CallError {
    return (state as CallError).error !== undefined;
}
