import firebase from 'firebase/app';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { fromCollectionRef } from 'rxfire/firestore';
import { from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { cardAdded, cardChanged, cardRemoved } from '../actions/firebaseCardActions';
import { getCurrentSet } from '../selectors/vocabularySelectors';
import Card from '../types/firestore/Card';
import RootState from '../types/state/RootState';
import StoreDependencies from '../types/state/StoreDependencies';

function handleChange({ type, doc, newIndex }: firebase.firestore.DocumentChange): Action | null {
    const data = doc.data() as Card;

    if (type === 'removed') {
        return cardRemoved(doc.id, data);
    }

    if (!Array.isArray(data.sides)) {
        return null;
    }

    if (data.sides.some(side => !side.id)) {
        console.error('At least one side of card is missing a createdAt timestamp', data);
        return null;
    }

    if (type === 'added') {
        return cardAdded(doc.id, data, newIndex);
    }

    if (type === 'modified') {
        return cardChanged(doc.id, data, newIndex);
    }

    return null;
}

function loadCardsForSet(firestore: firebase.firestore.Firestore, setId: string): Observable<Action> {
    const query = firestore
        .collection('sets')
        .doc(setId)
        .collection('cards')
        .orderBy('createdAt');

    return fromCollectionRef(query).pipe(
        switchMap(snapshot => from(snapshot.docChanges())),
        map(handleChange),
        filter(action => !!action),
    ) as Observable<Action>;
}

export default function loadCardsEpic(
    actions$: Observable<Action>,
    state: StateObservable<RootState>,
    { firestore }: StoreDependencies,
): Observable<Action> {
    return state.pipe(
        map(snapshot => getCurrentSet(snapshot)),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        filter(set => !!set),
        switchMap(set => loadCardsForSet(firestore, set?.id as string))
    );
}
