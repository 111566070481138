import { createAction } from 'deox';
import CardSet from '../types/firestore/CardSet';

export const setAdded = createAction(
    '[Firebase Set] Added',
    resolve => (id: string, set: CardSet, newIndex: number) => resolve({ id, set, newIndex }),
);

export const setRemoved = createAction(
    '[Firebase Set] Removed',
    resolve => (id: string, set: CardSet) => resolve({ id, set }),
);

export const setChanged = createAction(
    '[Firebase Set] Updated',
    resolve => (id: string, set: CardSet, newIndex: number) => resolve({ id, set, newIndex }),
);
