import { createAction } from 'deox';
import Card from '../types/firestore/Card';

export const cardAdded = createAction(
    '[Firebase Card] Added',
    resolve => (id: string, card: Card, newIndex: number) => resolve({ id, card, newIndex }),
);

export const cardRemoved = createAction(
    '[Firebase Card] Removed',
    resolve => (id: string, card: Card) => resolve({ id, card }),
);

export const cardChanged = createAction(
    '[Firebase Card] Updated',
    resolve => (id: string, card: Card, newIndex: number) => resolve({ id, card, newIndex }),
);
