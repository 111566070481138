import { Card, CardContent, createStyles, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Delete from '@material-ui/icons/Delete';
import React from 'react';
import ContentEditable from 'react-contenteditable';

interface Props {
    vocable: string;
    currentSide: number;
    maxSides: number;
    onChange(text: string): void;
    onClickPreviousSide(): void;
    onClickNextSide(): void;
    onClickDelete(): void;
    onClickAdd(): void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        display: 'flex',
        maxHeight: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(2, 2, 1, 2),
    },
    counter: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

        '& > *': {
            margin: 0,
        }
    },
    textContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        overflow: 'hidden',
    },
    text: {
        maxHeight: '100%',
        minHeight: '1em',
        width: '100%',
    }
}));

export default function VocabularyCard(props: Props) {
    const {
        vocable,
        currentSide,
        maxSides,
        onChange,
        onClickPreviousSide,
        onClickNextSide,
        onClickDelete,
        onClickAdd,
    } = props;
    const classes = useStyles();

    return (
        <Card className={classes.card} elevation={4}>
            <CardContent className={classes.content}>
                <div className={classes.textContainer}>
                    <ContentEditable
                        className={classes.text}
                        html={vocable || ''}
                        onChange={event => onChange(event.target.value)}
                    />
                </div>
                <div className={classes.counter}>
                    <IconButton onClick={() => onClickAdd()}><Add /></IconButton>
                    <IconButton onClick={() => onClickPreviousSide()}><ArrowLeft /></IconButton>
                    <Typography color="textSecondary" gutterBottom>
                        {currentSide}/{maxSides}
                    </Typography>
                    <IconButton onClick={() => onClickNextSide()}><ArrowRight /></IconButton>
                    <IconButton onClick={() => onClickDelete()}><Delete /></IconButton>
                </div>
            </CardContent>
        </Card>
    );
}
