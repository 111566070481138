import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Menu,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CardSet from '../types/state/CardSet';

interface SetData {
    title: string;
}

interface Props {
    open: boolean;
    set?: CardSet;
    onSubmit(data: SetData): void;
    onDelete?(): void;
    onClose(): void;
}

const useStyles = makeStyles(theme => createStyles({
    deleteButton: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
    },
    deleteMenu: {
        color: theme.palette.error.main,
    },
}));

export default function VocabularyCardSetDialog(props: Props) {
    const { onClose, onSubmit, onDelete, open, set } = props;

    const [title, setTitle] = useState(set?.title ?? '');
    useEffect(() => {
        setTitle(set?.title ?? '');
    }, [set]);

    const [deleteAnchor, setDeleteAnchor] = useState<null | HTMLElement>(null);
    const classes = useStyles();

    function handleDelete() {
        setDeleteAnchor(null);
        onDelete?.();
    }

    const result: SetData = { title };

    return (
        <Dialog onClose={() => onClose()} open={open} fullWidth>
            <DialogTitle>{set?.title ? `${set.title} bearbeiten` : 'Neues Set erstellen'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier kannst du
                    {set?.title ? `die Eigenschaften von ${set.title} bearbeiten` : 'ein neues Set anlegen'}
                    .
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Bezeichnung"
                    type="text"
                    value={title}
                    onChange={event => setTitle(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                {onDelete && set && (
                    <Button classes={{ root: classes.deleteButton }} onClick={event => setDeleteAnchor(event.currentTarget)}>Löschen</Button>
                )}
                <Button onClick={() => onClose()}>Abbrechen</Button>
                <Button color="primary" onClick={() => onSubmit(result)}>Speichern</Button>

                {onDelete && set && (
                    <Menu
                        anchorEl={deleteAnchor}
                        keepMounted
                        open={Boolean(deleteAnchor)}
                        onClose={() => setDeleteAnchor(null)}
                    >
                        <MenuItem
                            classes={{ root: classes.deleteMenu }}
                            onClick={handleDelete}
                        >
                            Wirklich löschen!
                        </MenuItem>
                    </Menu>
                )}
            </DialogActions>
        </Dialog>
    );
}
