import RootState from '../types/state/RootState';
import { isLoading } from './callStateSelectors';

export function isVocabularyLoading(state: RootState) {
    const { callState } = state.vocabulary;

    return isLoading(callState);
}

export function getCurrentSet(state: RootState) {
    const { currentSet } = state.vocabulary;

    if (!currentSet) {
        return;
    }

    return state.vocabulary.sets[currentSet];
}

export function getSets(state: RootState) {
    return state.vocabulary.setStack.map(id => state.vocabulary.sets[id]);
}

export function getCurrentCard(state: RootState) {
    const { cards, currentCard } = state.vocabulary;

    if (!currentCard) {
        return;
    }

    return cards[currentCard];
}

export function getCurrentVocable(state: RootState) {
    const { currentSide } = state.vocabulary;

    const side = getCurrentCard(state)?.sides?.find(side => side.id === currentSide);

    return side?.text ?? '';
}

export function getChangeDirection(state: RootState) {
    return state.vocabulary.changeDirection;
}

export function getCurrentSideIndex(state: RootState) {
    const { currentSide } = state.vocabulary;

    return getCurrentCard(state)?.sides?.findIndex(side => side.id === currentSide) ?? -1;
}

export function getCurrentSideNumber(state: RootState) {
    return getCurrentSideIndex(state) + 1;
}

export function getNumberOfSides(state: RootState) {
    return getCurrentCard(state)?.sides?.length ?? 0;
}

export function getCurrentCardNumber(state: RootState) {
    const { cardStack, currentCard } = state.vocabulary;

    if (!currentCard) {
        return 0;
    }

    return cardStack.indexOf(currentCard) + 1;
}

export function getNumberOfCards(state: RootState) {
    return state.vocabulary.cardStack?.length ?? 0;
}
