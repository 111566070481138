import { createStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import DrawerContext, { DrawerContextValue } from './DrawerContext';

interface Props {
    children?: ReactNode;
}

const useStyles = makeStyles(theme => createStyles({
    container: {
        height: '100%',
        display: 'flex',
    },
}));


export default function LayoutContainer(props: Props) {
    const { children } = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [isDrawerOpen, setIsDrawerOpen] = useState(!isMobile);
    useEffect(() => setIsDrawerOpen(!isMobile), [isMobile]);

    const drawerState = useMemo<DrawerContextValue>(
        () => ({
            isOpen: isDrawerOpen,
            toggle() {
                setIsDrawerOpen(!isDrawerOpen);
            },
        }),
        [isDrawerOpen],
    );

    return (
        <DrawerContext.Provider value={drawerState}>
            <div className={classes.container}>
                {children}
            </div>
        </DrawerContext.Provider>
    );
};
