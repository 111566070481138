import { ActionCreator } from 'deox';
import { Action } from 'redux';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { showNextCard, showPreviousCard, turnCardLeft, turnCardRight } from '../actions/vocabularyCardUiActions';

const actionMap: Record<string, ActionCreator<any>> = {
    ArrowLeft: turnCardLeft,
    ArrowRight: turnCardRight,
    ArrowUp: showPreviousCard,
    ArrowDown: showNextCard,
};

function isInputElement(element: HTMLElement | null): boolean {
    return element instanceof HTMLInputElement
        || element instanceof HTMLTextAreaElement
        || !!element?.isContentEditable;
}

export default function keyboardEpic(): Observable<Action> {
    return fromEvent<KeyboardEvent>(document, 'keydown').pipe(
        filter(event => !!actionMap[event.key] && !isInputElement(event.target as HTMLElement)),
        tap(event => event.preventDefault()),
        map(event => actionMap[event.key]()),
    );
}
