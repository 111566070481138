import { combineEpics } from 'redux-observable';
import deleteSetEpic from './deleteSetEpic';
import keyboardEpic from './keyboardEpic';
import loadCardsEpic from './loadCardsEpic';
import loadSetsEpic from './loadSetsEpic';
import saveNewCardEpic from './saveNewCardEpic';
import saveSetEpic from './saveSetEpic';
import saveUpdatedCardEpic from './saveUpdatedCardEpic';

export default combineEpics(
    keyboardEpic,
    loadSetsEpic,
    loadCardsEpic,
    saveNewCardEpic,
    saveUpdatedCardEpic,
    saveSetEpic,
    deleteSetEpic,
);
