import { CircularProgress, createStyles, Fab, IconButton, Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCreationRequested,
    setDeletionRequested,
    setDialogCanceled,
    setDialogSubmitted,
    setEditRequested,
    setSelected,
} from '../actions/setUiActions';
import { addCard, showNextCard, showPreviousCard } from '../actions/vocabularyCardUiActions';
import { getCurrentlyEditedSet, isSetDialogOpen } from '../selectors/setDialogSelectors';
import { getCurrentCardNumber, getCurrentSet, getNumberOfCards, getSets, isVocabularyLoading } from '../selectors/vocabularySelectors';
import AppBar from './layout/AppBar';
import LayoutContainer from './layout/LayoutContainer';
import MainContent from './layout/MainContent';
import NavigationDrawer from './layout/NavigationDrawer';
import SetList from './SetList';
import VocabularyCardSetDialog from './VocabularyCardSetDialog';
import { VocabularyCardSwiper } from './VocabularyCardSwiper';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        controlContainer: {
            display: 'flex',
            padding: 0,
            alignItems: 'center',
        },
        addCard: {
            position: 'absolute',
            right: `${theme.spacing(2)}px`,
            bottom: `${theme.spacing(2)}px`,
        },
    });
});

export default function VocabularyPage() {
    const classes = useStyles();
    const isLoading = useSelector(isVocabularyLoading);
    const maxCards = useSelector(getNumberOfCards);
    const currentCardNumber = useSelector(getCurrentCardNumber);
    const sets = useSelector(getSets);
    const currentSet = useSelector(getCurrentSet);

    const dialogSet = useSelector(getCurrentlyEditedSet);
    const isDialogOpen = useSelector(isSetDialogOpen);

    const dispatch = useDispatch();

    return (
        <LayoutContainer>
            <VocabularyCardSetDialog
                open={isDialogOpen}
                set={dialogSet}
                onClose={() => dispatch(setDialogCanceled())}
                onDelete={() => dialogSet && dispatch(setDeletionRequested(dialogSet))}
                onSubmit={values => dispatch(setDialogSubmitted({ id: dialogSet?.id, ...values }))}
            />
            <AppBar />
            <NavigationDrawer>
                <SetList
                    sets={sets}
                    selectedSet={currentSet}
                    onSelect={set => dispatch(setSelected(set))}
                    onEdit={set => dispatch(setEditRequested(set))}
                    onCreate={() => dispatch(setCreationRequested())}
                />
            </NavigationDrawer>
            <MainContent>
                <div className={classes.container}>
                    {isLoading && <CircularProgress />}
                    {!isLoading && !currentSet && (
                        <Typography variant="h4" color="textSecondary">
                            {'Bitte wähle ein Set aus oder '}
                            <Link href="#" onClick={() => dispatch(setCreationRequested())}>
                                lege ein neues
                            </Link>
                            {' an!'}
                        </Typography>
                    )}
                    {!isLoading && !!currentSet && maxCards === 0 && (
                        <Typography variant="h4" color="textSecondary">
                            Dieses Set ist leer.
                        </Typography>
                    )}
                    {!isLoading && maxCards > 0 && (
                        <>
                            <VocabularyCardSwiper />
                            <div className={classes.controlContainer}>
                                <IconButton onClick={() => dispatch(showPreviousCard())}><ArrowLeftIcon /></IconButton>
                                <Typography>
                                    {`Karte ${currentCardNumber} von ${maxCards}`}
                                </Typography>
                                <IconButton onClick={() => dispatch(showNextCard())}><ArrowRightIcon /></IconButton>
                            </div>
                        </>
                    )}
                    {currentSet && (
                        <Fab className={classes.addCard} color="primary" onClick={() => dispatch(addCard())}><AddIcon /></Fab>
                    )}
                </div>
            </MainContent>
        </LayoutContainer>
    );
}
