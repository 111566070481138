import { createReducer } from 'deox';
import {
    setCreationRequested,
    setDeletionRequested,
    setDialogCanceled,
    setDialogSubmitted,
    setEditRequested,
} from '../actions/setUiActions';
import SetDialogState from '../types/state/SetDialogState';

const initialState: SetDialogState = {
    isOpen: false,
    set: undefined,
};

export default createReducer(initialState, handleAction => [
    handleAction(setCreationRequested, () => ({
        isOpen: true,
        set: undefined,
    })),
    handleAction(setEditRequested, (state, { payload }) => ({
        isOpen: true,
        set: payload,
    })),
    handleAction(setDialogSubmitted, (state) => ({
        ...state,
        isOpen: false,
    })),
    handleAction(setDeletionRequested, (state) => ({
        ...state,
        isOpen: false,
    })),
    handleAction(setDialogCanceled, (state) => ({
        ...state,
        isOpen: false,
    })),
]);
