import { ofType } from 'deox';
import firebase from 'firebase/app';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, switchMapTo } from 'rxjs/operators';
import { setDialogSubmitted } from '../actions/setUiActions';
import RootState from '../types/state/RootState';
import StoreDependencies from '../types/state/StoreDependencies';

type SubmitValues = ReturnType<typeof setDialogSubmitted>['payload'];

async function saveSet(firestore: firebase.firestore.Firestore, values: SubmitValues): Promise<void> {
    const setsRef = firestore.collection('sets');

    if (values.id) {
        await setsRef.doc(values.id).update({
            title: values.title,
        });
    } else {
        await setsRef.add({
            createdAt: firebase.firestore.Timestamp.now(),
            title: values.title,
            cardCount: 0,
        });
    }
}

export default function saveSetEpic(
    actions$: Observable<Action>,
    state: StateObservable<RootState>,
    { firestore }: StoreDependencies,
): Observable<Action> {
    return actions$.pipe(
        ofType(setDialogSubmitted),
        switchMap(({ payload }) => saveSet(firestore, payload)),
        switchMapTo(EMPTY),
    );
}
