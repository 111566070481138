import { createAction } from 'deox';
import CardSet from '../types/state/CardSet';

interface DialogData {
    id? :string;
    title: CardSet['title'];
}

export const setSelected = createAction(
    '[Vocabulary Set UI] Set selected',
    resolve => (set: CardSet) => resolve(set),
);

export const setCreationRequested = createAction('[Vocabulary Set UI] Set creation requested');

export const setDeletionRequested = createAction(
    '[Vocabulary Set UI] Set deletion requested',
    resolve => (set: CardSet) => resolve(set),
);

export const setEditRequested = createAction(
    '[Vocabulary Set UI] Set edit requested',
    resolve => (set: CardSet) => resolve(set),
);

export const setDialogSubmitted = createAction(
    '[Vocabulary Set UI] Set dialog submitted',
    resolve => (set: DialogData) => resolve(set),
);

export const setDialogCanceled = createAction('[Vocabulary Set UI] Set dialog canceled');
