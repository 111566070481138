import firebase from 'firebase';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { fromCollectionRef } from 'rxfire/firestore';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { setAdded, setChanged, setRemoved } from '../actions/firebaseSetActions';
import CardSet from '../types/firestore/CardSet';
import RootState from '../types/state/RootState';
import StoreDependencies from '../types/state/StoreDependencies';

function handleChange({ type, doc, newIndex }: firebase.firestore.DocumentChange): Action | null {
    const data = doc.data() as CardSet;

    if (type === 'removed') {
        return setRemoved(doc.id, data);
    }

    if (type === 'added') {
        return setAdded(doc.id, data, newIndex);
    }

    if (type === 'modified') {
        return setChanged(doc.id, data, newIndex);
    }

    return null;
}

function handleSetChanges(firestore: firebase.firestore.Firestore): Observable<Action> {
    const query = firestore
        .collection('sets')
        .orderBy('title');

    return fromCollectionRef(query).pipe(
        switchMap(snapshot => from(snapshot.docChanges())),
        map(handleChange),
        filter(action => !!action),
    ) as Observable<Action>;
}

export default function loadVocabularySetEpic(
    actions$: Observable<Action>,
    state: StateObservable<RootState>,
    { firestore }: StoreDependencies,
): Observable<Action> {
    return handleSetChanges(firestore);
}
